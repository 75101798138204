import React, { useEffect } from 'react'
import check from './../assets/img/check.svg'
import ReactGA from 'react-ga4'
import BackHome from '../components/utils/BackHome'

const Response = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [])

    return (
        <section className="success">
            <div className="container-s">
                <div className="content">
                    <img src={check} alt="logo" className="success" />
                    <h2>¡Muchas Gracias!</h2>
                    <h3>Hemos recibido su respuesta</h3>
                </div>
                <BackHome />
            </div>
        </section>
    )
}

export default Response
